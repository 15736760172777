import React, { useState } from 'react';
import splitImage from './split_trade_image.webp';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const ScrollingImages = () => {
    const containerStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        justifyContent: 'space-between',
    };

    const textContainerStyle = {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontWeight: 525,
        fontSize: '1.25em',
        position: 'sticky',
        top: 0,
        padding: '0 20px',
        width: '50vw',
    };

    const imageContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
    };

    const imageStyle = {
        marginBottom: '100px',
        maxWidth: '100%',
        WebkitMaskImage:
            'linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0) 100%)',
        maskImage:
            'linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0) 100%)',
        transform: 'translateX(20vw)',
    };

    const imageTexts = [
        <p key="text1">
            <span
                style={{
                    background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                }}
            >
                See the Smart Money
            </span>{' '}
            <span style={{ color: 'gray' }}>
                move in real-time. Set filters and alerts to match your strategy
            </span>
        </p>,
        <p key="text2">
            <span
                style={{
                    background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                }}
            >
                Keep tabs on
            </span>{' '}
            <span style={{ color: 'gray' }}>
                trending options and volume spikes
            </span>
        </p>,
        <p key="text3">
            <span
                style={{
                    background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                }}
            >
                Analyze historical flow and
            </span>{' '}
            <span style={{ color: 'gray' }}>
                plan your next move
            </span>
        </p>,
    ];
    const [currentText, setCurrentText] = useState(imageTexts[0]);

    // Create refs for each image
    const [ref1, inView1] = useInView();
    const [ref2, inView2] = useInView();
    const [ref3, inView3] = useInView();

    // Update text based on image in view
    React.useEffect(() => {
        if (inView1) setCurrentText(imageTexts[0]);
        else if (inView2) setCurrentText(imageTexts[1]);
        else if (inView3) setCurrentText(imageTexts[2]);
    }, [inView1, inView2, inView3]);

    return (
        <div style={containerStyle}>
            <div style={textContainerStyle}>
                <motion.div
                    key={currentText.key}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    {currentText}
                </motion.div>
            </div>
            <div style={imageContainerStyle}>
                <img
                    ref={ref1}
                    src="https://firebasestorage.googleapis.com/v0/b/liteflow-40a37.appspot.com/o/updated_dashboard_sc.png?alt=media&token=be8e9381-ba41-43e4-aecd-c20f3f9ba7b6"
                    alt="Flow"
                    style={imageStyle}
                />
                <img
                    ref={ref2}
                    src="https://firebasestorage.googleapis.com/v0/b/liteflow-40a37.appspot.com/o/home_screenshot.png?alt=media&token=4eecf2d3-a05c-417f-b345-0bca55296afb"
                    alt="Dashboard"
                    style={imageStyle}
                />
                <img 
                ref={ref3} 
                src="https://firebasestorage.googleapis.com/v0/b/liteflow-40a37.appspot.com/o/historical_flow_screenshot.png?alt=media&token=3896ebf2-4e8b-44d4-b19a-1d17a70e5b43" 
                alt="Historical Flow" 
                style={imageStyle} />
            </div>
        </div>
    );
};

export default ScrollingImages;