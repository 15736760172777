export const ShootingStarButton = ({ text, onClick }) => {
    return (
        <button
            onClick={onClick}
            className="mt-6 mb-6 group relative flex justify-center items-center overflow-hidden rounded-full px-4 py-1 shadow-[0_1000px_0_0_hsl(0_0%_20%)_inset] text-sm font-regular text-white glow-on-hover">
            <span className="spark mask-gradient animate-flip before:animate-rotate absolute inset-0 h-[100%] w-[100%] overflow-hidden rounded-full [mask:linear-gradient(white, transparent_50%)] before:absolute before:aspect-square before:w-[200%] before:rotate-[-90deg] before:bg-[conic-gradient(from_0deg,transparent_0_340deg,white_360deg)] before:content-[''] before:[inset:0_auto_auto_50%] before:[translate:-50%_-15%]" />
            <span className="backdrop absolute inset-[1px] rounded-full bg-black" />

            <span className="z-20 mr-2">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path d="M3.24182 2.32181C3.3919 2.23132 3.5784 2.22601 3.73338 2.30781L12.7334 7.05781C12.8974 7.14436 13 7.31457 13 7.5C13 7.68543 12.8974 7.85564 12.7334 7.94219L3.73338 12.6922C3.5784 12.774 3.3919 12.7687 3.24182 12.6782C3.09175 12.5877 3 12.4252 3 12.25V2.75C3 2.57476 3.09175 2.4123 3.24182 2.32181ZM4 3.57925V11.4207L11.4288 7.5L4 3.57925Z" fill="#fff" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
            </span>

            <span className="z-10">{text}</span>
        </button>
    );
};
