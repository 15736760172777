import '../styles/navbarstyles.css';
import bull from '../bullflow_3.png';

import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import addEventToFirestore from '../EventHelper';
import { firestore } from '../firebase';

const NewNavigationBar = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [checkedLogin, setCheckedLogin] = useState(false);
    const [NoSubscription, setNoSubscription] = useState(false);
    const navigate = useNavigate();

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // console.log("STB");
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                // console.log(`User is signed in: ${user.email}`);
                // console.log("STB1");
                // Create a query against the collection
                const paymentsQuery = query(collection(firestore, "payments"), where("user", "==", user.email));

                // Execute the query
                const paymentQuerySnapshot = await getDocs(paymentsQuery);

                // Checks if any matching documents were found
                const paymentDocExists = !paymentQuerySnapshot.empty;

                if (paymentDocExists) {
                    // Get the first document from the query snapshot
                    const paymentDoc = paymentQuerySnapshot.docs[0];
                    const subscriptionType = paymentDoc.data().subscriptionType;

                    if (subscriptionType === 'monthly' || subscriptionType === 'yearly') {
                        // Handle subscription type
                        // console.log(`User has a ${subscriptionType} subscription.`);
                        setIsLoggedIn(true);
                    } else {
                        // Handle other cases
                        console.log('User does not have a monthly or yearly subscription.');
                        setNoSubscription(true);
                        setIsLoggedIn(true);
                    }
                } else {
                    // console.log("STB89");
                    setNoSubscription(true);
                    setIsLoggedIn(true);
                }
            } else {
                // console.log("STB99");
                setIsLoggedIn(false);
            }
            setCheckedLogin(true);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const paddingStyle = windowWidth > 768 ? '40px 60px' : '20px 40px';
    const handleSignUpClick = () => {
        window.gtag('event', 'Top bar Sign Up button', {
            'event_category': 'Button',
            'event_label': 'Top bar sign up ',
            'value': 1
        });
        addEventToFirestore("Click Sign Up");
        navigate('/SignUp')
    };
    const handlePricingClick = () => {
        window.gtag('event', 'Top bar pricing button', {
            'event_category': 'Button',
            'event_label': 'Top bar pricing ',
            'value': 1
        });
        addEventToFirestore("Click Pricing");
        navigate('/Pricing')
    };
    const handleLoginClick = () => {
        // You can navigate when button is clicked
        addEventToFirestore("Click Login");
        navigate('/login');
    };
    const handleLogoutClick = async () => {
        addEventToFirestore("Click Logout");
        const auth = getAuth();
        try {
            await signOut(auth);
            console.log("Successfully signed out");
            navigate("/"); // Navigate to the homepage
            window.location.reload(); // Refresh the page after navigating
        } catch (error) {
            console.error("Sign out error 48252");
        }
    };
    
    const handleAffiliateClick = () => {
        window.gtag('event', 'Top bar affiliate button', {
            'event_category': 'Button',
            'event_label': 'Top bar affiliate ',
            'value': 1
        });
        window.open('https://bullflow.tolt.io/')
    }
    const handleAPIClick = () => {
        window.gtag('event', 'Top bar API button', {
            'event_category': 'Button',
            'event_label': 'Top bar API ',
            'value': 1
        });
        addEventToFirestore("Click updates");
        navigate('/api');
    }
    const handleBlogClick = () => {
        window.gtag('event', 'Top bar updates button', {
            'event_category': 'Button',
            'event_label': 'Top bar updates ',
            'value': 1
        });
        addEventToFirestore("Click updates");
        navigate('/updates');
    }
    const handleFAQclick = () => {
        window.gtag('event', 'Top Bar FAQ button', {
            'event_category': 'Button',
            'event_label': 'Top Bar FAQ ',
            'value': 1
        });
        addEventToFirestore("Click FAQ");
        navigate('/faq');
    }
    const handleFlowClick = () => {
        window.gtag('event', 'Top Bar Flow Preview button', {
            'event_category': 'Button',
            'event_label': 'Top Bar Flow Preview ',
            'value': 1
        });
        addEventToFirestore("Click flow");
        navigate('/app');
    }
    const handleDashboardclick = () => {
        window.gtag('event', 'Top bar dashboard button', {
            'event_category': 'Button',
            'event_label': 'Top bar dashboard ',
            'value': 1
        });
        addEventToFirestore("Click Dashboard");
        navigate('/home');
    }
    const handleBullClick = () => {
        window.gtag('event', 'Top bar bull logo', {
            'event_category': 'Button',
            'event_label': 'Top bar dashboard ',
            'value': 1
        });
        addEventToFirestore("Click Bull Logo");
        navigate('/');
    }
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                padding: paddingStyle,
                backgroundColor: 'transparent',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                className: 'navbar'
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={bull} alt='Logo' style={{ marginRight: '20px', height: '45px', cursor:'pointer' }} 
                onClick={handleBullClick}/>
                {windowWidth > 768 && (
                    <>
                        <button className="customButton" onClick={handlePricingClick}>Pricing</button>
                        <button className="customButton" onClick={handleFAQclick}>FAQ</button>
                        <button className="customButton" onClick={handleAffiliateClick}>Affiliates</button>
                        <button className="customButton" onClick={handleAPIClick}>API</button>
                    </>
                )}
            </div>
            <div>
                {isLoggedIn ? (
                    <button className="text-sm font-semibold text-white py-2.5 px-5 animate-color-and-glow" onClick={handleLogoutClick}>Logout</button>
                ) : (
                    <button className="text-sm font-semibold text-white py-2.5 px-5 animate-color-and-glow" onClick={handleLoginClick}>Login</button>
                )}
                {isLoggedIn ? (
                    <button className="text-sm bg-slate-800 no-underline group cursor-pointer relative shadow-2xl shadow-zinc-900 rounded-full p-px text-xs font-semibold leading-6  text-white inline-block" onClick={handleDashboardclick}>
                        <span className="absolute inset-0 overflow-hidden rounded-full">
                            <span className="absolute inset-0 rounded-full bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span>
                        </span>
                        <div className="relative flex space-x-2 items-center z-10 rounded-full bg-zinc-950 py-0.5 px-4 ring-1 ring-white/10 ">
                            <span>{`Dashboard`}</span>
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M10.75 8.75L14.25 12L10.75 15.25"
                                ></path>
                            </svg>
                        </div>
                        <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-[#FFD70000] via-[#FFD70080] to-[#FFD70000] transition-opacity duration-500 group-hover:opacity-40"></span>
                    </button>
                ) : (
                    <button className="text-sm bg-slate-800 no-underline group cursor-pointer relative shadow-2xl shadow-zinc-900 rounded-full p-px text-xs font-semibold leading-6  text-white inline-block" onClick={handleSignUpClick}>
                        <span className="absolute inset-0 overflow-hidden rounded-full">
                            <span className="absolute inset-0 rounded-full bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span>
                        </span>
                        <div className="relative flex space-x-2 items-center z-10 rounded-full bg-zinc-950 py-0.5 px-4 ring-1 ring-white/10 ">
                            <span>{`Start Free Trial`}</span>
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M10.75 8.75L14.25 12L10.75 15.25"
                                ></path>
                            </svg>
                        </div>
                        <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-[#FFD70000] via-[#FFD70080] to-[#FFD70000] transition-opacity duration-500 group-hover:opacity-40"></span>
                    </button>
                )}
            </div>
        </div>
    );
};

export default NewNavigationBar;
