import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { CircularProgress } from '@mui/material';
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from './firebase';
import "firebase/auth";
import "firebase/firestore";
const SubVerification = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const auth = getAuth();

    const handleNav = () => {
        navigate('/app')
    };
    useEffect(() => {
        const authSubscriber = onAuthStateChanged(auth, setUser);

        return authSubscriber;
    }, []);
    useEffect(() => {
        if (user) {
            const intervalId = setInterval(async () => {
                console.log("c:c");
                const q = query(collection(firestore, "payments"), where("user", "==", user.email));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    handleNav();
                }
            }, 2000);

            return () => clearInterval(intervalId);
        }
    }, [firestore, user, handleNav]);


    useEffect(() => {
        if (window._tfa) {
            console.log("Sending conversion event");
            window._tfa.push({ notify: 'event', name: 'conversion', id: 1768835 });
        }
        if (window.rdt) {
            window.rdt('track', 'Purchase');
        }
    }, []);



    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            flexDirection: 'column',
        }}>
            <h3 style={{ marginBottom: '12px' }}>Verifying Bullflow Subscription. You will be redirected to the dashboard...</h3>
            <CircularProgress />

        </div>
    );
}

export default SubVerification;
