import React, { useState, useEffect } from 'react';

const BlurredBackground = () => {
  const [spots, setSpots] = useState([]);

  useEffect(() => {
    // Function to create a spot with random properties
    const createSpot = (id) => {
      const size = Math.floor(Math.random() * 300) + 400; // Size between 400 and 700px
      return {
        id,
        size,
        top: Math.random() * 100, // Percentage for top position
        left: Math.random() * 100, // Percentage for left position
        color: `hsl(${Math.random() * 360}, 70%, 50%)`, // Random color
        animationDelay: Math.random() * 30, // Delay between 0 and 30 seconds
      };
    };

    // Initialize spots
    const spotsArray = [];
    for (let i = 0; i < 2; i++) {
      spotsArray.push(createSpot(i));
    }
    setSpots(spotsArray);

    // Update spots every 60 seconds to create morphing effect
    const interval = setInterval(() => {
      setSpots((prevSpots) =>
        prevSpots.map((spot) => ({
          ...spot,
          top: Math.random() * 100,
          left: Math.random() * 100,
          color: `hsl(${Math.random() * 360}, 70%, 50%)`,
        }))
      );
    }, 60000); // Update every 60 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="background-container">
      <style>{`
        .background-container {
          position: relative;
          width: 100%;
          height: 100vh;
          overflow: hidden;
        }

        .background-spot {
          position: absolute;
          border-radius: 50%;
          filter: blur(200px); /* Increased blur for larger lights */
          animation: fadeInOut 60s ease-in-out infinite;
          transition: top 60s ease-in-out, left 60s ease-in-out, background-color 60s linear;
        }

        @keyframes fadeInOut {
          0% {
            opacity: 0;
            transform: scale(0.5);
          }
          25% {
            opacity: 0.6;
          }
          50% {
            opacity: 0;
            transform: scale(1);
          }
          75% {
            opacity: 0.6;
          }
          100% {
            opacity: 0;
            transform: scale(0.5);
          }
        }

        /* Styles for the frosty quadrants */
        .quadrant {
          position: absolute;
          width: 50%;
          height: 50%;
          backdrop-filter: blur(20px);
          -webkit-backdrop-filter: blur(20px); /* For Safari */
          background: rgba(255, 255, 255, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.3);
          box-shadow: inset 0 0 0px 1px rgba(255, 255, 255, 0.1);
        }

        .top-left {
          top: 0;
          left: 0;
        }

        .top-right {
          top: 0;
          right: 0;
        }

        .bottom-left {
          bottom: 0;
          left: 0;
        }

        .bottom-right {
          bottom: 0;
          right: 0;
        }
      `}</style>

      {/* Blurred background spots */}
      {spots.map((spot) => (
        <div
          key={spot.id}
          className="background-spot"
          style={{
            width: spot.size,
            height: spot.size,
            top: `${spot.top}%`,
            left: `${spot.left}%`,
            backgroundColor: spot.color,
            animationDelay: `${spot.animationDelay}s`,
          }}
        ></div>
      ))}

      {/* Frosty quadrants */}
      <div className="quadrant top-left"></div>
      <div className="quadrant top-right"></div>
      <div className="quadrant bottom-left"></div>
      <div className="quadrant bottom-right"></div>
    </div>
  );
};

export default BlurredBackground;
