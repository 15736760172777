import { useEffect, useState } from 'react';
import getDateString, { formatNumberWithCommas, getMostRecentWeekday } from "../Utils";
import { collection, query, where, onSnapshot, limit } from "firebase/firestore";
import { firestore } from '../firebase';

const MarketDetailsComponent = ({
    isSearching = false, ticker = "SPY",
    date = getDateString(),
    collectionName = `sentiment_tracking${date}`
}) => {
    const [p2CRatio, setP2CRatio] = useState(0);
    const [putFlow, setPutFlow] = useState(0);
    const [callFlow, setCallFlow] = useState(0);
    const [callsPercentage, setCallsPercentage] = useState(0.0);
    const [putsPercentage, setPutsPercentage] = useState(0.0);
    const [tickerPrice, setTickerPrice] = useState(0.0);
    const [tickerChangeFromPrev, setTickerChangeFromPrev] = useState(0.0);
    const [bullishFlow, setBullishFlow] = useState(0);
    const [bearishFlow, setBearishFlow] = useState(0);
    const [putPremiumTotal, setPutPremiumTotal] = useState(0);
    const [callPremiumTotal, setCallPremiumTotal] = useState(0);
    const [putOtmAverage, setPutOtmAverage] = useState(0.0);
    const [callOtmAverage, setCallOtmAverage] = useState(0.0);
    const [titleText, setTitleText] = useState("Overall Market");
    const [isSearching_, setIsSearching_] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(0.0);
    const [bearishSentimentPercent, setBearishSentimentPercent] = useState(0);
    const [bullishSentimentPercent, setBullishSentimentPercent] = useState(0);
    const [bullishOrBearish, setBullishOrBearish] = useState("Neutral");
    const [tickerForSearch, setTickerForSearch] = useState("");
    const [VTPercentChange, setVTPercentChange] = useState(0.0);
    const [articleDetails, setArticleDetails] = useState(null);
    useEffect(() => {
        // console.log("Ticker changed: " + ticker);
        setTickerForSearch(ticker);
        setIsSearching_(true);
    }, [ticker]);
    useEffect(() => {
        // console.log("setIsSearching_: " + isSearching);
        setIsSearching_(isSearching);
    }, [isSearching]);
    useEffect(() => {
        if (bullishSentimentPercent === bearishSentimentPercent) {
            setBullishOrBearish('Neutral');
        } else if (bullishSentimentPercent > bearishSentimentPercent) {
            setBullishOrBearish('Bullish');
        } else {
            setBullishOrBearish('Bearish');
        }
    }, [bullishSentimentPercent, bearishSentimentPercent]);



    useEffect(() => {
        let isMounted = true;
        const fetchData = async (isVt = false) => {
            try {
                const mostRecentWeekDay = getMostRecentWeekday();
                let url;
                let searchTicker = tickerForSearch
                if (searchTicker === "") {
                    searchTicker = "SPY";
                    // console.log("Ticker is empty, searching spy");
                }
                if (isVt) {
                    url = `https://us-central1-liteflow-40a37.cloudfunctions.net/stonks?stockTicker=VT&date=${mostRecentWeekDay}`;
                } else {
                    url = `https://us-central1-liteflow-40a37.cloudfunctions.net/stonks?stockTicker=${searchTicker}&date=${mostRecentWeekDay}`;

                }
                const response = await fetch(url);

                if (!response.ok) {
                    console.log("HTTP error getting stock ticker price data");
                }


                const result = await response.json();
                const currentPrice = Number(result.latestPrice).toFixed(2);
                setCurrentPrice(currentPrice);
                const previousClose = result.prevClose;
                const percentageChange = ((currentPrice - previousClose) / previousClose) * 100;
                if (isVt) {
                    setVTPercentChange(
                        isNaN(percentageChange) ? "N/A" : Number(percentageChange).toFixed(2)
                    );
                } else {
                    // console.log(tickerForSearch + " percentrage change: " + percentageChange);
                    setTickerPrice(
                        isNaN(result.latestPrice) ? "N/A" : Number(result.latestPrice).toFixed(2)
                    );
                    setTickerChangeFromPrev(
                        isNaN(percentageChange) ? "N/A" : Number(percentageChange).toFixed(2)
                    );
                }
            } catch (err) {
                console.error("Ticker data error 189373673" + err);
            }
        };


        fetchData();
        if (tickerForSearch === "") {
            fetchData(true);
        }

        if (isMounted) {
            fetchArticleDetails();
        }

        return () => {
            isMounted = false;
        };
    }, [tickerForSearch]);

    useEffect(() => {
        let unsubscribe;

        const fetchFirstActiveDocument = async () => {
            // console.log("fetchFirstActiveDocument");
            try {
                const sentimentRef = collection(firestore, collectionName);
                let orderedTickerSentimentRef;
                if (tickerForSearch != "") {
                    setTitleText(tickerForSearch);
                    // console.log("Setting title text to " + tickerForSearch);
                    orderedTickerSentimentRef = query(
                        sentimentRef,
                        where("ticker", "==", tickerForSearch),
                        limit(1)
                    );
                } else {
                    setTitleText("Overall Market");
                    // console.log("Setting title text to Overall Market ");
                    orderedTickerSentimentRef = query(
                        sentimentRef,
                        where("active", "==", true),
                        limit(1)
                    );

                }
                unsubscribe = onSnapshot(orderedTickerSentimentRef, (snapshot) => {
                    const data = snapshot.docs[0]?.data();
                    if (data) {
                        const putFlow = data.putFlow;
                        const callFlow = data.callFlow;
                        const putToCallRatio = putFlow / callFlow;
                        const bearishFlow = data.bearishTradeCount;
                        const bullishFlow = data.bullishTradeCount;
                        const putOtmAverage = data.putFlow_average_otm_percent;
                        const callOtmAverage = data.callFlow_average_otm_percent;
                        const putPremiumTotal = data.putFlow_premium;
                        const callPremiumTotal = data.callFlow_premium;
                        if (putToCallRatio === NaN) {
                            setP2CRatio("inf");
                        } else {
                            setP2CRatio(Number(putToCallRatio.toFixed(2)));
                        }

                        setCallFlow(callFlow || 0);
                        setPutFlow(putFlow || 0);
                        setBullishFlow(bullishFlow || 0);
                        setBearishFlow(bearishFlow || 0);
                        setPutPremiumTotal(putPremiumTotal || 0);
                        setCallPremiumTotal(callPremiumTotal || 0);
                        setPutOtmAverage(putOtmAverage || 0);
                        setCallOtmAverage(callOtmAverage || 0);
                        setBullishSentimentPercent((callFlow / (callFlow + putFlow)) * 100);
                        setBearishSentimentPercent((putFlow / (callFlow + putFlow)) * 100);
                        if (bullishFlow === 0) {
                            setBullishSentimentPercent(0);
                        } else {
                            setBullishSentimentPercent((bullishFlow / (bullishFlow + bearishFlow)) * 100);
                        }

                        if (bearishFlow === 0) {
                            setBearishSentimentPercent(0);
                        } else {
                            setBearishSentimentPercent((bearishFlow / (bullishFlow + bearishFlow)) * 100);
                        }
                        if (bullishSentimentPercent === bearishSentimentPercent) {
                            setBullishOrBearish('Neutral');
                        } else if (bullishSentimentPercent > bearishSentimentPercent) {
                            setBullishOrBearish('Bullish');
                        } else {
                            setBullishOrBearish('Bearish');
                        }
                        setCallsPercentage(Number((callFlow / (callFlow + putFlow) * 100).toFixed(1)));
                        setPutsPercentage(Number((putFlow / (callFlow + putFlow) * 100).toFixed(1)));
                    } else {
                        console.log("fb error 27745");
                    }
                });
            } catch (err) {
                console.log("fb error: 88897865");
            }
        };

        fetchFirstActiveDocument();

        return () => {
            if (unsubscribe) {
                // console.log("Unsubbing sentiment");
                unsubscribe();
            }
        }
    }, [date, collectionName, tickerForSearch]);

    const fetchArticleDetails = async () => {
        if (tickerForSearch === "") {
            return;
        }
        // console.log(`Fetching article details-${tickerForSearch}-`);
        try {
            const response = await fetch(
                `https://api.bullflow.io/v1/getStockArticle?ticker=${tickerForSearch}`
            );

            if (!response.ok) {
                console.log("HTTP error getting article details");
                return;
            }

            const article = await response.json();
            setArticleDetails(article);
        } catch (err) {
            console.error("Article details error: " + err);
        }
    };
    return (
        <div style={{
            width: '60%',
            height: '100%',
            backgroundColor: '#000000',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'top',
            paddingLeft: '12px',
            paddingRight: '12px',
            paddingTop: '8px',
            paddingBottom: '8px'

        }}>
            {/* Put to Call Ratio */}
            <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '7px' }}>
                    <p style={{
                        color: titleText === "Overall Market" ? '#879097' : "#FFF",
                        fontWeight: 550,
                        fontSize: '0.85em',
                        margin: 0
                    }}>
                        {isSearching_ ? titleText : "Overall Market"}
                    </p>
                    {isSearching_ && articleDetails ? (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '0px',
                            width: '85px',
                            backgroundColor: tickerChangeFromPrev >= 0 ? 'rgba(75, 183, 144, 0.3)' : 'rgba(204, 55, 83, 0.3)',
                            borderRadius: '5px',
                            padding: '3px 7.5px',
                        }}>
                            <p style={{
                                fontWeight: 550,
                                color: tickerChangeFromPrev >= 0 ? '#20d46b' : '#cc3753',
                                fontSize: '0.65em',
                                margin: 0
                            }}>
                                {isSearching_ ? "" : "SPY"} {isSearching ? currentPrice : ""}  {tickerChangeFromPrev}%
                            </p>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                {isSearching_ && articleDetails ? (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '250px', // Adjust the width to match the parent container
                        height: '60px',
                        alignItems: 'flex-start', // Align the content to the left
                        gap: '0px',
                        backgroundColor: '#36363680',
                        borderRadius: '5px',
                        overflow: 'hidden',
                        padding: '3px 7.5px',
                        marginTop: '5px'
                    }}>
                        <a href={articleDetails.article_url} target="_blank" rel="noopener noreferrer">
                            <p style={{
                                color: '#FFF',
                                fontWeight: 500,
                                fontSize: '0.65em',
                                margin: 0,
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                                // wordWrap: 'break-word',
                                // textOverflow: 'ellipsis'
                            }}>
                                {articleDetails.title.length > 120 ? articleDetails.title.slice(0, 120) + '...' : articleDetails.title}
                            </p>
                        </a>
                        <p style={{ color: '#879097', fontSize: '0.7em', margin: 0 }}>
                            {articleDetails.publisher.name}
                        </p>
                    </div>
                ) : (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '0px',
                        width: '85px',
                        backgroundColor: tickerChangeFromPrev >= 0 ? 'rgba(75, 183, 144, 0.3)' : 'rgba(204, 55, 83, 0.3)',
                        borderRadius: '5px',
                        padding: '3px 7.5px',
                    }}>
                        <p style={{
                            fontWeight: 550,
                            color: tickerChangeFromPrev >= 0 ? '#20d46b' : '#cc3753',
                            fontSize: '0.65em',
                            margin: 0
                        }}>
                            {isSearching_ ? "" : "SPY"} {isSearching ? currentPrice : ""}  {tickerChangeFromPrev}%
                        </p>
                    </div>
                )}
                {!isSearching_ && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '85px',
                        alignItems: 'center',
                        gap: '0px',
                        backgroundColor: tickerChangeFromPrev >= 0 ? 'rgba(75, 183, 144, 0.3)' : 'rgba(204, 55, 83, 0.3)',
                        borderRadius: '5px',
                        padding: '3px 7.5px',
                        marginTop: '5px'
                    }}>
                        <p style={{
                            fontWeight: 550,
                            color: tickerChangeFromPrev >= 0 ? '#20d46b' : '#cc3753',
                            fontSize: '0.6em',
                            margin: 0
                        }}>
                            VT {VTPercentChange}%
                        </p>
                    </div>
                )}
            </div>




            {/* Put to call */}
            <div>
                <p style={{ color: '#879097', fontWeight: 550, fontSize: '0.8em', marginBottom: '0px' }}>Put to Call</p>
                <p style={{ fontSize: '12px', fontWeight: 550, fontSize: '0.9em', marginTop: '0px' }}>{isNaN(p2CRatio) ? "∞" : p2CRatio}</p>
                <div style={{
                    height: '7.5px',
                    width: '100%',
                    backgroundColor: '#555',
                    borderRadius: '5px',
                    marginTop: '6px',
                }}>
                    <div style={{
                        height: '100%',
                        width: p2CRatio ? '100%' : `${p2CRatio * 100}%`, // This line has been modified
                        backgroundColor: callFlow > putFlow ? '#20d46b' : '#cc3753',
                        borderRadius: '5px',
                    }} />

                </div>
            </div>

            {/* Puts Total */}
            <div>
                <p style={{ color: '#879097', fontWeight: 550, fontSize: '0.8em', marginBottom: '0px' }}>Puts</p>
                <p style={{ fontSize: '12px', fontWeight: 550, fontSize: '0.9em', marginTop: '0px' }}>{formatNumberWithCommas(putFlow)}</p>
                <div style={{
                    height: '7.5px',
                    width: '100%',
                    backgroundColor: '#555',
                    borderRadius: '5px',
                    marginTop: '6px',
                }}>
                    <div style={{
                        height: '100%',
                        width: `${putsPercentage}%`,
                        backgroundColor: '#cc3753',
                        borderRadius: '5px',
                    }} />
                </div>
            </div>

            {/* Calls total */}
            <div>
                <p style={{ color: '#879097', fontWeight: 550, fontSize: '0.8em', marginBottom: '0px' }}>Calls</p>
                <p style={{ fontSize: '12px', fontWeight: 550, fontSize: '0.9em', marginTop: '0px' }}>{formatNumberWithCommas(callFlow)}</p>
                <div style={{
                    height: '7.5px',
                    width: '100%',
                    backgroundColor: '#555',
                    borderRadius: '5px',
                    marginTop: '6px',
                }}>
                    <div style={{
                        height: '100%',
                        width: `${callsPercentage}%`,
                        backgroundColor: '#20d46b',
                        borderRadius: '5px',
                    }} />
                </div>
            </div>
        </div >
    );
};

export default MarketDetailsComponent;
