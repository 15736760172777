import React, { useEffect, useState } from "react";
import { Container, ThemeProvider, createTheme } from "@mui/material";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { collection, doc, getDoc, setDoc, getDocs, query, where } from 'firebase/firestore';
import { firestore, functions } from './firebase';
import { GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail } from "firebase/auth";
import googleLogoPath from './g_icon.svg';
import { Helmet } from 'react-helmet';
import xLogoWhite from './x-logo-white.png';
import { TwitterAuthProvider } from "firebase/auth";
import { Form, Input, Button, Alert } from 'antd';
import { loadStripe } from "@stripe/stripe-js";
import { httpsCallable } from "firebase/functions";
import { Spinner } from "@nextui-org/react";
const stripePromise = loadStripe('pk_live_51NNbxVJBifvfVJGR8LZPTzcjkTFjRR9Gjp7pX2zWBOsPLCJt1mAhhAf82VQOzbrk8zH2Bi4kP2QohPwHzk8Rrbh700sfre53E6');
const theme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700', // Change this as per your requirement
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700', // Change this as per your requirement
                    },
                },
                input: {
                    color: 'white',
                    height: '1.5em',
                    padding: '10px 12px',
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "grey",
                    "&.Mui-focused": {
                        color: "white" // Change this as per your requirement
                    },
                    "&:hover": {
                        color: "white" // Change this as per your requirement
                    },
                }
            }
        }
    }
});

const SignInScreen = () => {
    const [form] = Form.useForm(); // Define the form instance here
    const navigate = useNavigate();
    const auth = getAuth();
    const [signInError, setSignInError] = useState('');
    const [showEmailForm, setShowEmailForm] = useState(false);
    const [showPasswordReset, setShowPasswordReset] = useState(false); // New state to control visibility of password reset
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                const checkPaymentsAndNavigate = async () => {
                    const paymentsQuery = query(collection(firestore, "payments"), where("user", "==", user.email));

                    const paymentQuerySnapshot = await getDocs(paymentsQuery);

                    const paymentDocExists = !paymentQuerySnapshot.empty;

                    if (paymentDocExists) { // checks if the payment exists in both collections
                        // Get the first document from the query snapshot
                        const paymentDoc = paymentQuerySnapshot.docs[0];
                        const subscriptionType = paymentDoc.data().subscriptionType;

                        if (subscriptionType === 'monthly' || subscriptionType === 'yearly') {
                            // Handle subscription type
                            // console.log(`User has a ${subscriptionType} subscription.`);
                            navigate('/app');
                        } else {
                            const subscriptionFlow = searchParams.get('renew');
                            if (subscriptionFlow === "m") {
                                if (user.email) {
                                    setLoading(true);
                                    handleMonthlyCheckout(user.email);
                                }
                            } else if (subscriptionFlow === "y") {
                                if (user.email) {
                                    setLoading(true);
                                    handleYearlyCheckout(user.email);
                                }
                            } else {
                                console.error("There was a problem reading the params");
                                navigate('/pricing');
                            }

                            // console.log('User does not have a monthly or yearly subscription.');
                        }
                    } else {
                        await setDoc(doc(collection(firestore, "users"), user.email), {
                            username: user.displayName,
                            email: user.email,
                        });
                        const subscriptionFlow = searchParams.get('renew');
                        if (subscriptionFlow === "m") {
                            if (user.email) {
                                setLoading(true);
                                handleMonthlyCheckout(user.email);
                            }
                        } else if (subscriptionFlow === "y") {
                            if (user.email) {
                                setLoading(true);
                                handleYearlyCheckout(user.email);
                            }
                        } else {
                            console.error("There was a problem reading the params");
                            navigate('/pricing');
                        }
                    }
                }
                checkPaymentsAndNavigate();
            } else {
                // console.log("No user is signed in");
            }
        });


        return () => {
            unsubscribe();
        };
    }, [auth, navigate]);


    const handleGoogleSignup = async () => {
        window.gtag('event', 'Sign In with google button', {
            'event_category': 'Button',
            'event_label': 'Sign In with google',
            'value': 1
        });
        const provider = new GoogleAuthProvider();
        const subscriptionFlow = searchParams.get('renew');

        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            performLoginCheckProcess(subscriptionFlow, user.email);
        } catch (error) {
            console.error("Error signing up with Google: ", error);
        }
    };

    const handleTwitterSignup = async () => {
        window.gtag('event', 'Sign In with X button', {
            'event_category': 'Button',
            'event_label': 'Sign In with X',
            'value': 1
        });
        const subscriptionFlow = searchParams.get('renew');
        const provider = new TwitterAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            // Handle the sign-in result here (e.g., update user profile, navigate)
            const email = result.user.email;
            if (email) {
                console.log("Twitter sign-in successful " + email);
                performLoginCheckProcess(subscriptionFlow, email);
            } else {
                console.log("Twitter sign-in successful, no email returned");
            }
        } catch (error) {
            console.error("Error signing up with Twitter:", error);
        }
    };

    const performLoginCheckProcess = async (subscriptionFlow, email) => {
        const userDoc = await getDoc(doc(collection(firestore, "users"), email));

        // Create a query against the collection
        const paymentsQuery = query(collection(firestore, "payments"), where("user", "==", email));

        // Execute the query
        const paymentQuerySnapshot = await getDocs(paymentsQuery);

        // Checks if any matching documents were found
        const paymentDocExists = !paymentQuerySnapshot.empty;

        if (userDoc.exists() && paymentDocExists) { // checks if the user exists in both collections
            // Get the first document from the query snapshot
            const paymentDoc = paymentQuerySnapshot.docs[0];
            const subscriptionType = paymentDoc.data().subscriptionType;

            if (subscriptionType === 'monthly' || subscriptionType === 'yearly') {
                // Handle subscription type
                // console.log(`User has a ${subscriptionType} subscription.`);
                navigate('/app');
            } else {
                const subscriptionFlow = searchParams.get('renew');
                if (subscriptionFlow === "m") {
                    if (email) {
                        setLoading(true);
                        handleMonthlyCheckout(email);
                    }
                } else if (subscriptionFlow === "y") {
                    if (email) {
                        setLoading(true);
                        handleYearlyCheckout(email);
                    }
                } else {
                    console.error("There was a problem reading the params");
                    navigate('/pricing');
                }
                // console.log('User does not have a monthly or yearly subscription.');
            }

        } else {
            await setDoc(doc(collection(firestore, "users"), email), {
                email: email,
            });

            if (subscriptionFlow === "m") {
                setLoading(true);
                handleMonthlyCheckout(email);
            } else if (subscriptionFlow === "y") {
                setLoading(true);
                handleYearlyCheckout(email);
            } else {
                console.error("There was a problem reading the params");
                navigate('/pricing');
            }
        }
    }
    const handleEmailSignIn = async (values) => {
        const { email, password } = values;
        const auth = getAuth();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            console.log('User signed in successfully.');
            const subscriptionFlow = searchParams.get('renew');

            performLoginCheckProcess(subscriptionFlow, user.email);
        } catch (error) {
            console.error("Error signing in with email and password:", error);
            if (error.message) {
                // Replace the firebase in error.message with a more user-friendly message
                setSignInError(error.message.replace('Firebase', 'Bullflow'));
            } else {
                setSignInError("An error occurred during sign in. Please try again later.");
            }
        }
    };

    const toggleEmailForm = () => {
        setShowEmailForm(!showEmailForm);
    };

    const handleForgotPassword = () => {
        setShowPasswordReset(!showPasswordReset); // Toggle visibility of password reset form
    };

    const handleSubmitPasswordReset = async () => {
        console.log(`Sending password reset email...`);
        const email = form.getFieldValue('email'); // Access the email value directly
        const auth = getAuth();

        if (!email) {
            alert("Please enter your email address.");
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            alert("Password reset email sent. Please check your inbox.");
            setShowPasswordReset(false);
        } catch (error) {
            console.error("Failed to send password reset email:", error);
            setSignInError("Error sending password reset email. Please try again. Or contact support@bullflow.io");
        }
    };

    // Function for handling monthly checkout
    async function handleMonthlyCheckout(userEmail) {
        window.gtag('event', 'Monthly Checkout from sign up screen', {
            'event_category': 'Button',
            'event_label': 'Monthly Checkout from sign up screen',
            'value': 1
        });
        try {
            const createCheckoutSession = httpsCallable(functions, 'createCheckoutSessionMonthly');
            const session = await createCheckoutSession({ email: userEmail, subscriptionType: "monthly", toltReferral: window.tolt_referral });
            const stripe = await stripePromise;

            const { error } = await stripe.redirectToCheckout({
                sessionId: session.data.sessionId,
            });

            if (error) {
                console.error("Checkout error 81121");
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.error("Checkout process failed:", error);
            return false;
        }
    }

    // Function for handling yearly checkout
    async function handleYearlyCheckout(userEmail) {
        window.gtag('event', 'Yearly Checkout from sign up screen', {
            'event_category': 'Button',
            'event_label': 'Yearly Checkout from sign up screen',
            'value': 1
        });
        try {
            const createCheckoutSession = httpsCallable(functions, 'createCheckoutSessionYearly');
            const session = await createCheckoutSession({ email: userEmail, subscriptionType: "yearly", toltReferral: window.tolt_referral });
            const stripe = await stripePromise;

            const { error } = await stripe.redirectToCheckout({
                sessionId: session.data.sessionId,
            });

            if (error) {
                console.error("Checkout error 81121");
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.error("Checkout process failed:", error);
            return false;
        }
    }


    const renderEmailForm = () => {
        if (showEmailForm) {
            return (
                <div style={{ animation: 'fadeInUp 1.0s ease-out forwards', marginTop: '30px' }}>
                    <Form form={form} onFinish={handleEmailSignIn} style={{ maxWidth: '300px', margin: 'auto' }}>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input placeholder="Email" type="email" />
                        </Form.Item>
                        {!showPasswordReset && (
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>
                        )}
                        {!showPasswordReset ? (
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: '#FFD700', borderColor: '#FFD700', color: '#040129' }}>
                                    Sign In
                                </Button>
                            </Form.Item>
                        ) : (
                            <Form.Item>
                                <Button type="primary" onClick={handleSubmitPasswordReset} style={{ width: '100%', backgroundColor: '#FFD700', borderColor: '#FFD700', color: '#040129' }}>
                                    Send Password Reset Email
                                </Button>
                            </Form.Item>
                        )}
                        <Form.Item>
                            <Button type="link" onClick={handleForgotPassword} style={{ float: 'right', marginTop: '0px' }}>
                                {showPasswordReset ? 'Back to Sign In' : 'Forgot password?'}
                            </Button>
                        </Form.Item>
                        {signInError && (
                            <div style={{ marginTop: '10px' }}>
                                <Alert message={signInError} type="error" showIcon />
                            </div>
                        )}
                    </Form>
                </div>
            );
        }
        return null;
    };


    return (
        <ThemeProvider theme={theme}>
            <style>
                {`
              @keyframes fadeInUp {
                from {
                  opacity: 0;
                  transform: translateY(20px);
                }
                to {
                  opacity: 1;
                  transform: translateY(0);
                }
              }
            `}
            </style>
            <Helmet>
                <title>Sign In</title>
                <meta name="description" content="Sign In - Bullflow" />
                <meta name="keywords" content="Bullflow unusual option flow platform" />
            </Helmet>
            <Container
                component="main"
                maxWidth="xs"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '50vh',
                    marginTop: '100px',
                    marginBottom: '180px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        opacity: 0,
                        transform: 'translateY(20px)',
                        animation: 'fadeInUp 1.0s ease-out forwards',
                    }}
                >
                    <h2
                        style={{
                            margin: '35px 0',
                            whiteSpace: 'nowrap',
                            fontSize: '1.5em',
                            fontWeight: 550,
                            background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            display: 'inline',
                        }}
                    >
                        Sign in to Bullflow
                    </h2>
                </div>

                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Spinner size="sm" color="secondary" />
                    </div>
                ) : (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <button
                                onClick={handleGoogleSignup}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'transparent',
                                    color: '#fff',
                                    padding: '10px 20px',
                                    border: '0.5px solid #4285F4',
                                    borderRadius: '50px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    outline: 'none',
                                    textShadow: '0 -1px rgba(0, 0, 0, 0.25)',
                                    fontFamily: 'Arial, sans-serif',
                                    height: '45px',
                                    opacity: 0,
                                    transform: 'translateY(20px)',
                                    animation: 'fadeInUp 1.0s ease-out 0.15s forwards',
                                }}
                            >
                                <img
                                    src={googleLogoPath}
                                    alt="Google Logo"
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        marginRight: '10px',
                                        backgroundColor: '#fff',
                                        padding: '2px',
                                        borderRadius: '2px',
                                    }}
                                />
                                Continue with Google
                            </button>
                            <button
                                onClick={handleTwitterSignup}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'transparent',
                                    color: '#fff',
                                    padding: '10px 20px',
                                    border: '0.5px solid #a9afb0',
                                    borderRadius: '50px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    outline: 'none',
                                    marginTop: '20px',
                                    textShadow: '0 -1px rgba(0, 0, 0, 0.25)',
                                    fontFamily: 'Arial, sans-serif',
                                    height: '45px',
                                    opacity: 0,
                                    transform: 'translateY(20px)',
                                    animation: 'fadeInUp 1.0s ease-out 0.3s forwards',
                                }}
                                type="button"
                            >
                                Continue with
                                <img
                                    src={xLogoWhite}
                                    alt="Logo"
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft: '10px',
                                    }}
                                />
                            </button>
                        </div>

                        {!showEmailForm && (
                            <div>
                                <div
                                    onClick={toggleEmailForm}
                                    style={{
                                        cursor: 'pointer',
                                        opacity: 0,
                                        animation: 'fadeInUp 1.0s ease-out 0.6s forwards',
                                        fontWeight: 550,
                                        fontSize: '0.9em',
                                        textAlign: 'center',
                                        marginTop: '20px',
                                    }}
                                >
                                    Continue with email
                                </div>
                            </div>
                        )}
                        {renderEmailForm()}
                    </>
                )}
            </Container>
        </ThemeProvider>
    );

};

export default SignInScreen;
