import { useEffect, useState } from "react";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";
import { Accordion, AccordionItem } from "@nextui-org/react";
import { BugOff, Sparkles } from "lucide-react";

const UpdatesPage = () => {
    const [updates, setUpdates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUpdates = async () => {
            try {
                const updatesCollection = collection(firestore, "_bullflow_updates");
                const q = query(updatesCollection, orderBy("firestoreTimestamp", "desc"));
                const querySnapshot = await getDocs(q);
                console.log(`Length of updates: ${querySnapshot.docs.length}`);
                const updatesData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    formattedTimestamp: doc.data().firestoreTimestamp.toDate().toLocaleString(),
                    ...doc.data(),
                }));
                console.log(`Update data: ${updatesData}`);
                setUpdates(updatesData);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching updates:", error);
            }
        };

        fetchUpdates();
    }, []);

    function getStartContent(type) {
        if (type === "feature") {
            return <Sparkles size={24} color="#007afc" />;
        } else if (type === "bugfix") {
            return <BugOff size={24} color="#20d46b" />;
        }
    }

    return (
        <div className={`updates-container ${isLoading ? 'fade-out' : 'fade-in'}`}>
            <div className="header">
                <h1
                    style={{
                        fontWeight: 'bold',
                        fontSize: '2rem',
                        marginTop: '20px',
                        marginBottom: '2rem',
                        background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        display: 'inline-block',
                    }}
                >
                    Bullflow Updates
                </h1>
            </div>
            {updates.length > 0 && (
                <div className="accordion-wrapper">
                    <Accordion variant="shadow">
                        {updates.map((update) => (
                            <AccordionItem
                                startContent={getStartContent(update.type)}
                                key={update.updateTitle}
                                title={update.updateTitle}
                                subtitle={update.formattedTimestamp}
                            >
                                <p style={{ wordWrap: 'break-word' }}>{update.updateDescription}</p>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            )}
            <style jsx>{`
                .updates-container {
                    display: flex;
                    flex-direction: column;
                    width: 100%; /* Changed from 100vw to 100% */
                    margin-bottom: 50px; /* Reduced from 225px to 50px */
                    opacity: 0;
                    transition: opacity 0.5s ease-in-out;
                    overflow-x: hidden; /* Prevent horizontal overflow */
                    /* Removed height: 90vh; */
                }
                .header {
                    text-align: center;
                    margin-bottom: 20px;
                }
                .accordion-wrapper {
                    width: 90%;
                    max-width: 600px;
                    margin: 0 auto;
                    padding: 0 10px; /* Added padding */
                }
                @media (min-width: 768px) {
                    .accordion-wrapper {
                        width: 50%;
                    }
                }
                .fade-in {
                    opacity: 1;
                }
                .fade-out {
                    opacity: 0;
                }
            `}</style>
        </div>
    );
}

export default UpdatesPage;
