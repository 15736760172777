"use client";
import React, { useState } from 'react';
import { HeroHighlight, Highlight } from '../components/ui/hero-highlight';
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet'; // Import Helmet

const AffiliateInfoPage = () => {
    const [isHovered, setIsHovered] = useState(false);

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start', // Ensure content starts at the top
        padding: '0',
        backgroundColor: '#000', // Optional: Add a background color for better glow visibility
        minHeight: '100vh', // Ensure the container covers at least the viewport height
    };

    // Handler for button click
    const handleButtonClick = () => {
        // Navigate to the sign-up page or handle the action you desire
        window.location.href = '/signup'; // Replace '/signup' with your desired URL

        // Alternatively, to open in a new tab:
        // window.open('/signup', '_blank', 'noopener,noreferrer');
    };

    return (
        <div style={containerStyle}>
            <Helmet>
                {/* Primary Meta Tags */}
                <title>Become a Bullflow Affiliate - Earn $10 per Referral</title>
                <meta name="description" content="Join Bullflow as an affiliate and earn $10 for each paid referral. Stream big players' options trades directly to your browser and start earning today!" />
                <meta name="keywords" content="Bullflow, affiliate program, earn money, referral program, options trading, financial affiliate" />
                <meta name="author" content="Bullflow Team" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                {/* Open Graph / Facebook Meta Tags */}
                <meta property="og:title" content="Become a Bullflow Affiliate - Earn $10 per Referral" />
                <meta property="og:description" content="Join Bullflow as an affiliate and earn $10 for each paid referral. Stream big players' options trades directly to your browser and start earning today!" />
                <meta property="og:image" content="https://www.bullflow.com/images/affiliate-banner.jpg" /> {/* Replace with your image URL */}
                <meta property="og:url" content="https://www.bullflow.com/affiliate" />
                <meta property="og:type" content="website" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Become a Bullflow Affiliate - Earn $10 per Referral" />
                <meta name="twitter:description" content="Join Bullflow as an affiliate and earn $10 for each paid referral. Stream big players' options trades directly to your browser and start earning today!" />
                <meta name="twitter:image" content="https://www.bullflow.com/images/affiliate-banner.jpg" /> {/* Replace with your image URL */}
            </Helmet>

            <HeroHighlight containerClassName="mb-0">
                <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: [20, -5, 0] }}
                    transition={{ duration: 0.5, ease: [0.4, 0.0, 0.2, 1] }}
                    className="text-2xl px-4 md:text-4xl lg:text-5xl font-bold text-neutral-700 dark:text-white max-w-4xl leading-relaxed lg:leading-snug text-center mx-auto"
                >
                    Become a Bullflow affiliate, and for <br /> each paid referral,
                    {" "}
                    <Highlight className="text-black dark:text-white">
                        you will earn $10
                    </Highlight>
                </motion.h1>
            </HeroHighlight>

            {/* Added Button Below HeroHighlight */}
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleButtonClick}
                className="mt-4 px-6 py-3 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700 transition-colors duration-300"
                aria-label="Join Bullflow Affiliate Program"
            >
                Join Now
            </motion.button>
        </div>
    );
};

export default AffiliateInfoPage;
