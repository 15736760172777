import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
// Function to log errors to Firebase Analytics
function logErrorToFirebase({ message, filename, lineno, colno }) {
  logEvent(analytics, 'exception', {
    description: message,
    fatal: true,
    filename,
    lineno,
    colno,
  });
}

// Global error handler
window.addEventListener('error', (event) => {
  const { message, filename, lineno, colno } = event;
  logErrorToFirebase({ message, filename, lineno, colno });
});

window.addEventListener('unhandledrejection', (event) => {
  const { reason } = event;
  logErrorToFirebase({ message: reason.toString() });
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <NextUIProvider>
    <main className="dark text-foreground bg-background">
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </main>
  </NextUIProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
