import { useNavigate } from "react-router-dom";
import WideBorderMagicButton from "./WideBorderMagicButton";

const CallToAction = () => {
    const nav = useNavigate();

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh', marginLeft: '32px', marginRight: '32px' }}>
            <div style={{ textAlign: 'center' }}>
                <h1
                    style={{
                        fontSize: '24px',
                        marginBottom: '20px',
                        fontWeight: 650,
                        fontSize: '2.75em',
                        background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                    }}
                >
                    Begin your flow trading journey
                </h1>
                <WideBorderMagicButton text="Try it Free" onClick={() => nav('/signup')} />
            </div>
        </div>
    );
};

export default CallToAction;