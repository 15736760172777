import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { Input } from 'antd';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: '6px',
    marginLeft: '4px',
    fontWeight: 'bold'
  },
  mainTitle: {
    marginLeft: '10px',
    marginRight: '5px',
    fontSize: '0.7em',
  },
  subtitle: {
    color: 'grey',
    fontSize: '0.45em',
    alignContent: 'center'
  },
  input: {
    margin: '0 10px',
    width: 'calc(50% - 20px)', // assuming there are 2 fields with 10px margin each, adjust accordingly

  },
});
function TickerFilter({ onlyShowTickersProp, excludeTickersProp, handleFilterChange }) {
  const classes = useStyles();
  const [excludeTickers, setExcludeTickers] = useState(excludeTickersProp);
  const [onlyShowTickers, setOnlyShowTickers] = useState(onlyShowTickersProp);

  const handleChange = (field, value) => {
    if (field === 'exclude') setExcludeTickers(value);
    if (field === 'onlyShow') setOnlyShowTickers(value);
    handleFilterChange(field, value);
  };

  useEffect(() => {
    setOnlyShowTickers(onlyShowTickersProp);
  }, [onlyShowTickersProp]);

  useEffect(() => {
    setExcludeTickers(excludeTickersProp);
  }, [excludeTickersProp]);

  return (
    <div>
      <style type="text/css">
        {`
          #exclude-tickers::placeholder, #only-show-tickers::placeholder {
            color: #838891;
          }
        `}
      </style>
      <div className={classes.header}>
        <p className={classes.mainTitle}>Filter by tickers</p>
        <p className={classes.subtitle}>(Create a list with spaces or commas)</p>
      </div>
      <div className={classes.root}>
        <Input
          id="only-show-tickers"
          placeholder="Show Tickers"
          className={`${classes.input}`}
          value={onlyShowTickers}
          style={{
            backgroundColor: '#000',
            border: '1px solid #000',
            color: '#fff',
          }}
          onChange={event => handleChange('onlyShow', event.target.value)}
        />
        <Input
          id="exclude-tickers"
          placeholder="Hide Tickers"
          className={`${classes.input}`}
          value={excludeTickers}
          style={{
            backgroundColor: '#000',
            border: '1px solid #000',
            color: '#fff',
          }}
          onChange={event => handleChange('exclude', event.target.value)}
        />
      </div>
    </div>
  );
}
export default TickerFilter;