import { useCallback, useEffect, useState } from 'react';
import { chipDataUpdated } from './DashboardGlobals';
import { Chip, Tooltip } from "@nextui-org/react";
import { getUserFavTickersByEmail, removeUserFavTicker } from '../FirestoreFunctions/Functions';
import { Edit } from 'lucide-react';

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

const QuickFilterAndFavoritesComponent = ({ 
    selectedQuickFilterChips = [], selectedFavoriteTickerChips = [], 
    onFavoriteTickerSelectionChange, onSelectionChange, 
    isHistoricalFlow = false, user, localFavoritesList, onFavTickerRemoved }) => {
    const debouncedOnSelectionChange = useCallback(debounce(onSelectionChange, 200), [onSelectionChange]);
    const debounceFavoriteTickerSelectionChange = useCallback(debounce(onFavoriteTickerSelectionChange, 200), [onFavoriteTickerSelectionChange]);

    const [favTickers, setFavTickers] = useState([]);
    const [isEditingFavTickers, setIsEditingFavTickers] = useState(false);

    const handleEditChipClick = () => {
        setIsEditingFavTickers(!isEditingFavTickers);
    };
    const removeTickerButtonHandler = async (ticker) => {
        // console.log("Removing ticker: ", ticker);
        if (user === null || user.email === null || user.email === "") {
            console.error("Favorite ticker removal error");
            return;
        }
        try {
            await removeUserFavTicker(user.email, ticker);
            onFavTickerRemoved(ticker);
        } catch (error) {
            console.error("Error in removeTickerButtonHandler");
            // Handle the error as needed, e.g., show a user-friendly message
        }
    };

    const handleChipClick = (key) => {
        window.gtag('event', 'quick_filter_chip_click', {
            'event_category': 'Button',
            'event_label': key,
        });
        const newSelectedChips = selectedQuickFilterChips.includes(key)
            ? selectedQuickFilterChips.filter(chipKey => chipKey !== key)
            : [...selectedQuickFilterChips, key];
        debouncedOnSelectionChange(newSelectedChips);
    };

    const handleFavoriteTickerChipClick = (ticker) => {
        window.gtag('event', 'favorite_ticker_chip_click', {
            'event_category': 'Button',
            'event_label': ticker,
        });
        const newSelectedChips = selectedFavoriteTickerChips.includes(ticker)
            ? selectedFavoriteTickerChips.filter(chipKey => chipKey !== ticker)
            : [...selectedFavoriteTickerChips, ticker];
        debounceFavoriteTickerSelectionChange(newSelectedChips);
    };
    const fetchTickers = async () => {
        try {
            if (user === null || user.email === null || user.email === "") {
                // console.error("Fav filter email is null or empty");
                return;
            }
            // console.log("Fetching tickers for email: ", user.email);
            const tickers = await getUserFavTickersByEmail(user.email);
            // console.log("Fetched tickers: ", tickers);
            // if localFavoritesList is shorter than tickers, only use the localFavoritesList
            // if (localFavoritesList.length < tickers.length && localFavoritesList.length > 0) {
            //     // console.log("Local favorites list is shorter than fetched tickers list");
            //     setFavTickers(localFavoritesList);
            //     return;
            // }
            const mergedTickers = Array.from(new Set([...tickers, ...localFavoritesList]));
            if (mergedTickers.length === 0) {
                setIsEditingFavTickers(false);
            }
            setFavTickers(mergedTickers);
        } catch (error) {
            console.error("Failed to fetch tickers: ", error);
        }
    };
    useEffect(() => {
        fetchTickers();
    }, [user, localFavoritesList]);

    return (
        <div style={{
            width: isHistoricalFlow ? '50%' : '40%',
            backgroundColor: '#000000',
            height: 'auto',
            maxHeight: 'fit-content',
            overflowX: 'auto',
            padding: favTickers.length === 0 ? '10px' : '2.5px 10px 10px 10px',
            borderRadius: '10px'
        }}>
            {favTickers.length > 0 ? (
                <div style={{
                    borderBottom: '0.35px solid #333333',
                    paddingBottom: '5px',
                    marginBottom: '5px',
                    marginTop: '5px'
                }}>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '2.5px',
                    }}>
                        {favTickers.map((ticker) => (
                            <Chip key={ticker} size="sm" variant="flat" radius="md"
                                onClose={isEditingFavTickers ? () => removeTickerButtonHandler(ticker) : undefined}
                                onClick={() => handleFavoriteTickerChipClick(ticker)}
                                classNames={{
                                    base: `${selectedFavoriteTickerChips.includes(ticker) ? 'bg-[#4bb78f33]' : 'bg-[#36363680]'} border-none cursor-pointer transition-colors duration-300 ease-in-out`,
                                    content: `${selectedFavoriteTickerChips.includes(ticker) ? 'text-[#20d46b]' : 'text-white'} hover:text-gray-300`
                                }}>
                                {ticker}
                            </Chip>
                        ))}
                        <Chip
                            onClick={handleEditChipClick}
                            size="sm" variant="flat" radius="md"
                            classNames={{
                                base: `bg-[#36363680] border-none cursor-pointer transition-colors duration-300 ease-in-out`,
                                content: `text-white hover:text-gray-300`
                            }}>
                            <Edit size={12} />
                        </Chip>
                    </div>
                </div>
            ) : (
                <p style={{
                    borderBottom: '0.25px solid #333333',
                    color: 'gray',
                    fontSize: '0.775rem',
                    fontStyle: 'italic',
                    marginTop: '0px',
                    marginBottom: '5px',
                    paddingBottom: '5px',
                    marginLeft: '2px',
                    textAlign: 'left',
                }}>
                    Favorite Tickers appear here
                </p>
            )}
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '2.5px'
            }}>
                {chipDataUpdated.map((chip) => (
                    <Tooltip closeDelay={1} key={chip.key} content={chip.tooltip} delay={1250}>
                        <Chip size="sm" variant="flat" radius="md"
                            onClick={() => handleChipClick(chip.key)}
                            classNames={{
                                base: `${selectedQuickFilterChips.includes(chip.key) ? 'bg-[#4bb78f33]' : 'bg-[#36363680]'} border-none cursor-pointer transition-colors duration-300 ease-in-out`,
                                content: `${selectedQuickFilterChips.includes(chip.key) ? 'text-[#20d46b]' : 'text-white'} hover:text-gray-300`
                            }}>
                            {chip.label}
                        </Chip>
                    </Tooltip>
                ))}
            </div>
        </div>
    );    
};

export default QuickFilterAndFavoritesComponent;