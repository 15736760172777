import React, { useState, useEffect } from 'react';
import { LightningBoltIcon, Crosshair2Icon, CounterClockwiseClockIcon } from "@radix-ui/react-icons";
import { SpotlightCard } from './SpotlightCard';
import { motion, AnimatePresence } from 'framer-motion';
import { GraduationCap, MonitorCheck, Send, TextSearch } from 'lucide-react';

const InfoCardsRow = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isSmallerScreen, setIsSmallerScreen] = useState(window.innerWidth < 2000);

    const updateMedia = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    });

    const cardList = [{
        title: 'Learn',
        description: 'Our educational videos and Discord community will teach you to find profitable trades and understand the flow.',
        icon: <GraduationCap size={38} color="#6e6e6e" strokeWidth={1.5} />,
    }, {
        title: 'Analyze',
        description: 'Watch smart money trade in real-time with a trained eye, and spot trades with a high profit potential.',
        icon: <TextSearch size={36} color="#6e6e6e" strokeWidth={1.5} />,
    }, {
        title: 'Execute',
        description: 'Feel confident trading the exact contract the smart money is trading, and profit from their expertise, and your own flow analysis.',
        icon: <MonitorCheck size={34} color="#6e6e6e" strokeWidth={1.5} />,
    }];


    return (
        <div style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-around',
            alignItems: isMobile ? 'center' : 'stretch',
            width: '95%',
            margin: '0 auto',
            marginTop: '60px',
            flexWrap: 'wrap',
        }}>
            <AnimatePresence>
                {cardList.map((card, index) => (
                    <div key={index} style={{ marginBottom: isMobile || isSmallerScreen ? '80px' : '0' }}>
                        <motion.div
                            key={index}
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.75, delay: index * 0.3 }}
                        >
                            <SpotlightCard
                                title={card.title}
                                description={card.description}
                                icon={card.icon}
                            />
                        </motion.div>
                    </div>
                ))}
            </AnimatePresence>
        </div>
    );
};

export default InfoCardsRow;
