import { useEffect, useState } from "react";
import { Accordion, AccordionItem } from '@nextui-org/react';
const FAQAccordion = ({ faqs }) => {
    const titleStyle = {
        textAlign: 'center',
        fontSize: '1.5em',
        fontWeight: 550,
        background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        display: 'inline',
        marginBottom: '20px'
    };
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 550);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <div
            style={{
                height: '90vh',
                width: '100%',
            }}
            className={`updates-container ${loading ? 'fade-out' : 'fade-in'}`}>
            <div
                style={{
                    display: 'inline-block',
                    marginBottom: '20px',
                    textAlign: 'center'
                }}
            >
                <h1 style={{
                    textAlign: "center",
                    fontWeight: "bold", fontSize: "2rem", marginTop: "20px",
                    marginBottom: "2rem",
                    background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    display: 'inline',
                }}>
                    Frequently Asked Questions
                </h1>
            </div>
            {faqs.length > 0 && (
                <div style={{ width: "50%", margin: "0 auto" }}>
                    <Accordion variant="shadow">
                        {faqs.map((faq, index) => (
                            <AccordionItem key={index} title={faq.question}>
                                <p>{faq.answer}</p>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            )}
            <style jsx>{`
        .updates-container {
          display: flex;
          flex-direction: column;
          width: 100vw;
          margin-bottom: 225px;
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
        }
        .fade-in {
          opacity: 1;
        }
        .fade-out {
          opacity: 0;
        }
      `}</style>
        </div>
    );
};
const FAQComponent = () => {
    const faqs = [
        { question: 'How does the 7 day free trial work?', answer: 'We offer a 7-day free trial on our subscription plans. This gives you a week to try out our product, absolutely free, to see if it\'s right for you. During your 7-day free trial, you\'ll have unrestricted access to all Bullflow features. If you decide not to continue, you can cancel your trial anytime within the 7-day period from your the Bullflow dashboard, and you won\'t be charged.' },
        { question: 'Where does the options flow data come from?', answer: 'Bullflow gets trade data from the Options Price Reporting Authority (OPRA). OPRA handles options data for all major US exchanges.' },
        { question: 'What is SigScore?', answer: 'SigScore, or Significance Score,  is a rating we assigned to all trades that Bullflow sees. The score is calculated using multiple factors including OTM Percentage, Days Until Expiration and Price to Premium Ratio. SigScore is not a definite predictor of the outcome of a trade, rather a way to identify abnormal or highly unsual trades at a glance.' },
        { question: 'How can I contact you if there\'s a billing problem?', answer: 'If you encounter any issues with billing or any other features, reach out to our support team: support@bullflow.io.' },
        { question: 'What\'s your cancellation policy?', answer: 'You can cancel your subscription at any time via the account menu in the Bullflow dashboard. You can still use Bullflow until the end of your last subscription period.' },
        { question: 'Will I get access to new features with my subscription plan?', answer: 'Yes. You will have immediate access to all new features as soon as they go live.' },
        { question: 'Will there be new features?', answer: 'Absolutely! You can expect new features added to Bullflow every month. We are working every day to improve Bullflow and we wecome all feature suggestions.' },
    ];

    return <FAQAccordion faqs={faqs} />;
};
export default FAQComponent;