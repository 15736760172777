import React from "react";
import InfoCardsRow from "./InfoCardRow";
import PropTypes from "prop-types";

const TitleWithCards = ({
    titleText = "The #1 Flow Trading Platform & Community",
    useGradient = false,
}) => {
    return (
        <>
            <style>
                {`
                @keyframes fadeIn {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }
                `}
            </style>
            {/* Container with the new background gradient and rounded corners */}
            <div
                style={{
                    padding: '40px 0px',    
                    width: '95%',             
                    margin: '100px 10px',       
                    boxSizing: 'border-box',
                    background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.065) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 1) 100%)',
                    borderRadius: '14px', // Added borderRadius for rounded corners
                }}
            >
                <div
                    style={{
                        textAlign: 'center',
                        overflow: 'hidden',
                        width: '100%',
                    }}
                >
                    <h1
                        id="hero-title"
                        style={{
                            opacity: 0,
                            animation: 'fadeIn 1.25s ease-out forwards',
                            zIndex: 2,
                            position: 'relative',
                            color: '#fff',
                            fontWeight: 700,
                            fontSize: 'clamp(36px, 3.35vw, 48px)',
                            lineHeight: '1.2',
                            marginBottom: '32px',
                        }}
                    >
                        {useGradient ? (
                            <span
                                style={{
                                    background: 'linear-gradient(to right, #8593ff, #fce774)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                }}
                            >
                                {titleText}
                            </span>
                        ) : (
                            titleText
                        )}
                    </h1>
                    
                    {/* Insert the InfoCardsRow component here */}
                    <InfoCardsRow />
                    
                    {/* Removed subtitle and image */}
                </div>
            </div>
        </>
    );
};

// Optional: Define PropTypes for better type-checking
TitleWithCards.propTypes = {
    titleText: PropTypes.string,
    useGradient: PropTypes.bool,
};

export default TitleWithCards;
