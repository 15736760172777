import React, { useState } from 'react';
import { collection, query, where, getDocs, setDoc, doc, addDoc } from "firebase/firestore";
import { auth, firestore } from './firebase'; // Ensure you have firestore initialized in your firebase.js
import { CircularProgress } from "@mui/material";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
const AffiliatePasswordScreen = () => {
    const [passwordInput, setPasswordInput] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSuccess = () => {
        createUserWithEmailAndPassword(auth, 'tester@bullflow.io', 'securePassword')
            .then((userCredential) => {
                // User creation successful
                const paymentRef = doc(firestore, 'payments', 'uniqueDocumentId');
                setDoc(paymentRef, {
                    user: 'tester@bullflow.io',
                    subscriptionStatus: 'complete',
                    subscriptionType: 'yearly',
                    timestamp: Date.now()
                })
                    .then(() => {
                        console.log('Payment document successfully written!');
                        navigate('/home');
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });
            })
            .catch((error) => {
                if (error.code === 'auth/email-already-in-use') {
                    // Attempt to sign in since the email is already in use
                    signInWithEmailAndPassword(auth, 'tester@bullflow.io', 'securePassword')
                        .then((userCredential) => {
                            console.log("Existing user signed in successfully.");
                            const paymentRef = doc(firestore, 'payments', 'uniqueDocumentId');
                            setDoc(paymentRef, {
                                user: 'tester@bullflow.io',
                                subscriptionStatus: 'complete',
                                subscriptionType: 'yearly',
                                timestamp: Date.now()
                            })
                                .then(() => {
                                    console.log('Payment document successfully written!');
                                    navigate('/home');
                                })
                                .catch((error) => {
                                    console.error("Error writing document: ", error);
                                });
                            navigate('/home');
                        })
                        .catch((signInError) => {
                            console.error("Error signing in existing user: ", signInError);
                        });
                } else {
                    // Handle other errors
                    console.error("Error creating user: ", error);
                }
            });
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setMessage('');
        try {
            // Save the attempt
            await addDoc(collection(firestore, '_free_access_password_attempts'), {
                passwordAttempted: passwordInput,
                timestamp: Date.now()
            });

            // Query the '_free_access_passwords' collection to see if the password exists
            const passwordsCollection = collection(firestore, '_free_access_passwords');
            const q = query(passwordsCollection, where('password', '==', passwordInput));

            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Password found
                const docData = querySnapshot.docs[0].data();
                const passwordTimestamp = docData.timestamp; // Assume timestamp is in Unix seconds

                const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix seconds
                const twoWeeksInSeconds = 14 * 24 * 60 * 60;

                if ((currentTime - passwordTimestamp) <= twoWeeksInSeconds) {
                    // Password is valid and not expired
                    setMessage('Password is valid.');
                    handleSuccess();
                } else {
                    // Password is expired
                    setMessage('Password has expired. Please contact an admin.');
                }
            } else {
                // Password not found
                setMessage('Invalid password. Please try again.');
            }
        } catch (error) {
            console.error('Error checking password:', error);
            setMessage('An error occurred. Please try again later.');
        }
        setIsLoading(false);
    };

    return (
        <div style={{
            backgroundColor: '#000',
            color: '#fff',
            padding: '1rem',
            textAlign: 'center',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
        }}>
            <h2 style={{
                marginBottom: '1rem',
                fontWeight: 600,
                fontSize: '1.5em',
                marginTop: '1rem',
            }}>
                Please enter the password given to you by a Bullflow admin.
            </h2>
            <form onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <input
                    type="password"
                    value={passwordInput}
                    onChange={(e) => setPasswordInput(e.target.value)}
                    style={{
                        backgroundColor: '#1a1b20',
                        color: '#fff',
                        border: '1px solid #555',
                        borderRadius: '4px',
                        padding: '0.8rem',
                        width: '80%',
                        maxWidth: '400px',
                        marginBottom: '1.5rem',
                        fontSize: '1em',
                    }}
                    placeholder="Enter password"
                    disabled={isLoading} // Disable input during loading
                />
                <button
                    type="submit"
                    disabled={isLoading || passwordInput.trim() === ''}
                    style={{
                        backgroundColor: '#8593ff',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        padding: '0.8rem 2rem',
                        fontSize: '1em',
                        cursor: isLoading ? 'default' : 'pointer',
                        fontWeight: 600,
                        opacity: isLoading ? 0.6 : 1,
                    }}
                >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
                </button>
            </form>
            {message && (
                <p style={{
                    marginTop: '1rem',
                    fontSize: '1em',
                    color: message.includes('valid') ? '#4caf50' : '#f44336',
                }}>
                    {message}
                </p>
            )}
        </div>
    );

};

export default AffiliatePasswordScreen;
