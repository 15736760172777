import { useState, useEffect } from 'react';
import { Slider, InputNumber } from 'antd';
import './antdesignslider.css';

function PremiumFilter({ handleFilterChange, minPremium, maxPremium }) {
  const firstSegmentValue = 5000000; // End of the first segment
  const minScrollValue = 9999;
  const maxScrollValue = 500000000; // Adjusted to match the label
  const firstSegmentPoint = 850; // 75% for the first segment, scaled to 1000
  const [value, setValue] = useState([minPremium, maxPremium]);

  useEffect(() => {
    if (value[0] !== minPremium || value[1] !== maxPremium) {
      setValue([minPremium, maxPremium]);
    }
  }, [minPremium, maxPremium, value]);

  const mapSliderValueToRange = (sliderValue) => {
    if (sliderValue <= firstSegmentPoint) {
      return (
        minScrollValue +
        ((firstSegmentValue - minScrollValue) * sliderValue) / firstSegmentPoint
      );
    } else {
      return (
        firstSegmentValue +
        ((maxScrollValue - firstSegmentValue) * (sliderValue - firstSegmentPoint)) /
        (1000 - firstSegmentPoint)
      );
    }
  };

  const mapValueToSlider = (val) => {
    if (val <= firstSegmentValue) {
      return (
        (firstSegmentPoint * (val - minScrollValue)) / (firstSegmentValue - minScrollValue)
      );
    } else {
      return (
        firstSegmentPoint +
        ((1000 - firstSegmentPoint) * (val - firstSegmentValue)) /
        (maxScrollValue - firstSegmentValue)
      );
    }
  };

  const onSliderChange = (sliderValue) => {
    const mappedValue = sliderValue.map(mapSliderValueToRange);
    setValue(mappedValue);
    handleFilterChange('min', mappedValue[0]);
    handleFilterChange('max', mappedValue[1]);
  };

  const tipFormatter = (sliderValue) => {
    const actualValue = Math.round(mapSliderValueToRange(sliderValue));
    return `$${actualValue.toLocaleString()}`;
  };

  const sliderValue = value.map(mapValueToSlider);

  const onInputNumberChange = (newValue, index) => {
    let updatedValue = [...value];
    updatedValue[index] = newValue;

    // If the input is empty, do not update the other input
    if (newValue === undefined || newValue === null || newValue === '') {
      setValue(updatedValue);
      handleFilterChange(index === 0 ? 'min' : 'max', newValue);
      return;
    }

    // Convert newValue to a number
    const numericValue = Number(newValue);

    // Ensure values are numbers and within range
    if (!isNaN(numericValue)) {
      // Update the value without enforcing minScrollValue
      updatedValue[index] = numericValue;

      // Ensure min <= max when both inputs have values
      if (updatedValue[0] !== undefined && updatedValue[1] !== undefined) {
        if (updatedValue[0] > updatedValue[1]) {
          if (index === 0) {
            updatedValue[1] = numericValue;
          } else {
            updatedValue[0] = numericValue;
          }
        }
      }

      setValue(updatedValue);
      handleFilterChange(index === 0 ? 'min' : 'max', numericValue);
    }
  };

  return (
    <div style={{ width: '90%', marginBottom: '0px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          fontWeight: 600,
          fontSize: '0.8em',
        }}
      >
        <p style={{ marginBottom: '0px' }}>Filter by Trade Value</p>
      </div>
      <div style={{ marginTop: '0px' }}>
        <Slider
          range
          min={0}
          max={1000}
          value={sliderValue}
          onChange={onSliderChange}
          tooltip={{
            formatter: tipFormatter,
            visible: true,
            zIndex: 15001,
            placement: 'topLeft', // Adjust the placement
          }}
        />

      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '0px',
        }}
      >
        <InputNumber
          id="min-premium"
          className="custom-input-number"
          style={{
            width: '45%',
            fontSize: '0.7em',
            marginRight: '0px',
            marginLeft: '0px',
            backgroundColor: '#000',
            border: '1px solid #000',
          }}
          step={10000}
          formatter={(value) =>
            `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          value={value[0] !== undefined ? Math.round(value[0]) : undefined}
          onChange={(newVal) => onInputNumberChange(newVal, 0)}
        />

        <InputNumber
          id="max-premium"
          className="custom-input-number"
          style={{
            width: '45%',
            fontSize: '0.7em',
            marginRight: '0px',
            marginLeft: '0px',
            backgroundColor: '#000',
            border: '1px solid #000',
          }}
          step={2500}
          formatter={(value) =>
            `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          value={value[1] !== undefined ? Math.round(value[1]) : undefined}
          onChange={(newVal) => onInputNumberChange(newVal, 1)}
        />

      </div>
    </div>
  );
}

export default PremiumFilter;
